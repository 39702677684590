<template>
  <div class="stat">
    <div class="row row-equal">
      <div class="flex mb-3 xs3 xl3">
        <va-card>
          <p style="color: grey; margin-bottom: 10px;">日销售额</p>
          <p
            class="display-2"
            style="text-align: center;"
            :style="{ color: this.$themes.primary }"
          >
            ¥ {{ this.today_sales }}
          </p>
          <hr color="#987cb9" size="1" />
          <p style="font-size: 90%;">总销售额：¥{{ this.total_sales }}</p>
          <p style="font-size: 90%;">昨日销售额：¥{{ this.yesterday_sales }}</p>
        </va-card>
      </div>

      <div class="flex mb-3 xs3 xl3">
        <va-card>
          <p style="color: grey; margin-bottom: 10px;">日拍照数</p>
          <p
            class="display-2"
            style="text-align: center;"
            :style="{ color: this.$themes.primary }"
          >
            {{ this.today_pic }}
          </p>
          <hr color="#987cb9" size="1" />
          <p style="font-size: 90%;">总拍照数：{{ this.total_pic }}</p>
          <p style="font-size: 90%;">昨日拍照数：{{ this.yesterday_pic }}</p>
        </va-card>
      </div>

      <div class="flex mb-3 xs3 xl3">
        <va-card>
          <p style="color: grey; margin-bottom: 10px;">电子版购买量</p>
          <p
            class="display-2"
            style="text-align: center;"
            :style="{ color: this.$themes.primary }"
          >
            {{ this.today_eorder }}
          </p>
          <hr color="#987cb9" size="1" />
          <p style="font-size: 90%;">转化率：+ {{ this.eorder_rate }}%</p>
          <p style="font-size: 90%;">
            昨日电子版购买量：{{ this.yesterday_eorder }}
          </p>
        </va-card>
      </div>

      <div class="flex mb-3 xs3 xl3">
        <va-card>
          <p style="color: grey; margin-bottom: 10px;">打印版购买量</p>
          <p
            class="display-2"
            style="text-align: center;"
            :style="{ color: this.$themes.primary }"
          >
            {{ this.today_porder }}
          </p>
          <hr color="#987cb9" size="1" />
          <p style="font-size: 90%;">转化率：+ {{ this.porder_rate }}%</p>
          <p style="font-size: 90%;">
            昨日打印版购买量：{{ this.yesterday_porder }}
          </p>
        </va-card>
      </div>
    </div>

    <div class="row">
      <div class="flex md12">
        <va-card class="chart-widget">
          <div class="flex row row-equal md10">
            <div class="flex">
              <va-button-toggle
                small
                outline
                slot="actions"
                v-model="toggle1"
                :options="options"
                color="info"
              />
            </div>
            <div class="flex">
              <va-button-toggle
                small
                outline
                v-model="toggle2"
                :options="optionst"
                color="gray"
              />
            </div>
          </div>
          <div class="flex row row-equal">
            <va-chart class="flex md8" :data="ChartData" type="vertical-bar" />

            <div class="flex md4">
              <va-data-table :fields="fields" :data="total"></va-data-table>
            </div>
          </div>
          <!-- <va-separator /> -->

          <!-- <va-checkbox :value="props.rowData.checked" @input="select(props.rowData)" /> -->

          <!-- <va-button-toggle small outline v-model="model" :options="options" color="info" /> -->
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "charts",
  data() {
    return {
      options: [
        { label: "销售额", value: "sales" },
        { label: "销售数", value: "count" },
        { label: "拍照数", value: "picnum" },
      ],
      toggle1: "sales",
      optionst: [
        { label: "本周", value: "week" },
        { label: "本月", value: "month" },
        { label: "本年", value: "year" },
      ],
      toggle2: "week",
      total: [],
      ChartData: {},

      today_sales: 0,
      today_pic: 0,
      today_eorder: 0,
      today_porder: 0,

      yesterday_sales: 0,
      yesterday_pic: 0,
      yesterday_eorder: 0,
      yesterday_porder: 0,

      total_sales: 0,
      total_pic: 0,
      total_eorder: 0,
      total_porder: 0,

      eorder_rate: 0,
      porder_rate: 0,
    };
  },
  watch:{
      toggle1(){
        console.log(this.toggle1);
        this.refreshData();
      },
      toggle2(){
        console.log(this.toggle2);
        this.refreshData();
      },

  },
  computed: {
    fields() {
      return [
        {
          name: "attributes",
          title: "本周期总额",
        },
        {
          name: "values",
          title: "数值",
        },
      ];
    },
  },
  mounted() {
    console.log("mounted");
    this.axios
      .get("/v1/data/today", {
        headers: {
          Authorization: "Bearer " + sessionStorage["access_token"],
        },
      })
      .then((res) => {
        this.today_sales = res.data["today_sales"];
        // console.log(this.today_sales);
        this.today_pic = res.data["today_pic"];
        this.today_eorder = res.data["today_eorder"];
        this.today_porder = res.data["today_porder"];

        this.yesterday_sales = res.data["yesterday_sales"];
        this.yesterday_pic = res.data["yesterday_pic"];
        this.yesterday_eorder = res.data["yesterday_eorder"];
        this.yesterday_porder = res.data["yesterday_porder"];

        this.total_sales = res.data["total_sales"];
        this.total_pic = res.data["total_pic"];
        this.total_eorder = res.data["total_eorder"];
        this.total_porder = res.data["total_porder"];

        if (this.today_pic != 0) {
          this.porder_rate = (this.today_porder / this.today_pic).toFixed(2);
          this.eorder_rate = (this.today_eorder / this.today_pic).toFixed(2);
        } else {
          this.porder_rate = 0;
          this.eorder_rate = 0;
        }

        console.log(res.data);
        // this.toggle1="count";
        // console.log(this.toggle1);
      })
      .catch((error) => {
        console.log(error);
      });

      this.refreshData();

  },
  methods: {
    refreshData() {
        console.log("refresh data")
        
        console.log(this.ChartData)
        console.log(this.ChartData.labels)
        // console.log(this.ChartData["labels"])

        this.axios
        .post("/v1/data/chart", 
            {
                'datatype':this.toggle1,
                'time':this.toggle2
            },
            {
            headers: {
            Authorization: "Bearer " + sessionStorage["access_token"],
            },
        })
        .then((res) => {
            console.log(res.data);
            console.log(this.ChartData.datasets[0]);
            if (this.toggle1 === "sales" || this.toggle1 === "count" )
            {
                
                this.ChartData = {
                labels: res.data["label"],
                datasets: [
                {
                    label: "电子版",
                    backgroundColor: this.$themes.primary,
                    borderColor: "transparent",
                    data: res.data["edata"],
                },
                {
                    label: "打印版",
                    backgroundColor: this.$themes.info,
                    borderColor: "transparent",
                    data: res.data["pdata"],
                },],};
                
                
            } 
            else if (this.toggle1 === "picnum"){
                this.ChartData = {
                labels: res.data["label"],
                datasets: [
                {
                    label: "拍照次数",
                    backgroundColor: this.$themes.primary,
                    borderColor: "transparent",
                    data: res.data["edata"],
                },],};
            } 
            else{
                console.log(this.toggle1);
            }
            if (this.toggle1==="sales")
            {
                this.total = [{ attributes: "销售总额", values: res.data["stat_tt"] },
                        { attributes: "电子版销售总额", values: res.data["stat_te"] },
                        { attributes: "打印版销售总额", values: res.data["stat_tp"] },]
            }
            else if (this.toggle1==="count")
            {
                this.total = [{ attributes: "销售总数", values: res.data["stat_tt"] },
                        { attributes: "电子版销售总数", values: res.data["stat_te"] },
                        { attributes: "打印版销售总数", values: res.data["stat_tp"] },]
            }
            else if (this.toggle1==="picnum")
            {
                this.total = [{ attributes: "拍照总数", values: res.data["stat_tt"] },]
            }
            
            

        })
        .catch((error) => {
            console.log(error);
        });
     
      return
    },
  },
};
</script>
